import Box from '@mui/material/Box';


export default function Terms() {
    return (
        <Box>

        <h1>Privacy Policy</h1>
        <p>Date last updated: November 16, 2023</p>

        <h2>OVERVIEW</h2>
        <p>The Regents of the University of Michigan (“MICHIGAN”) are making the Materials (as defined below) available to You (“You” or “Your”) through the cryoEDU website located at cryoEDU.org (“PROGRAM”). This Privacy Policy applies to the PROGRAM and data, videos, images, text, calculators, and related materials provided through or in connection with the PROGRAM (“PROGRAM Materials”). This Privacy Policy details the ways in which Your personal data may be collected, secured, shared, and used when You interact with PROGRAM or the PROGRAM Materials.</p>
        <p>This Privacy Policy does not cover the privacy practices of third parties, such as the practices of third-party social networking services, or our partners. In addition, our service may contain links to the websites or services of third parties. Such third-party services and websites operate independently of the PROGRAM and each has its own privacy and security practices. We encourage You to review their policies before submitting any information to them.</p>

        <h2>THE INFORMATION WE COLLECT</h2>
        <p>User-provided information – We collect and store information You provide when engaging with the PROGRAM, or when contacting us directly for other purposes or through other methods, such as over email. Information You provide generally will include:</p>
        <p>● Your name, email address, username, password, and other registration information;</p>
        <p>● Information You provide us when You contact us for help; and</p>
        <p>● Transaction-related information, such as when You create or submit requests to use the RELION simulator, or otherwise use features in the PROGRAM that rely on user input;</p>
        <p><i>Automatically Collected Information</i> – In addition, the PROGRAM may collect certain information automatically, such as the type of device You use, the IP address of Your device, Your operating system, the type of Internet browsers You use, location data or sites used to access/leave each page, and information about the way You use the PROGRAM, including Your digital interactions with the PROGRAM and the date, time, and length of Your visits. This information may be collected through the use of cookies and web beacons and is automatically stored in log files each time You visit the PROGRAM and log into the PROGRAM. Cookies administered by the PROGRAM are used to identify a user across page loads during a session and are therefore necessary for an integrated user experience. Some of this automatically collected data may be linked to user-provided information.</p>
        <p>The User-Provided Information and Automatically Collected Information shall be collectively referred to as “Application Data.”</p>

        <h2>HOW YOUR INFORMATION IS USED</h2>
        <p>MICHIGAN uses Application Data collected through the PROGRAM to enhance the PROGRAM’s performance and user experience and for other legitimate educational interests or as required by law. MICHIGAN does not sell or rent Your personal data.</p>
        <p>MICHIGAN may use Application Data:</p>
        <p>● To provide You with the PROGRAM: We use Your information for the purpose of providing You with access to the PROGRAM and the PROGRAM Materials.</p>
        <p>● To respond to technical support questions: We use Your information to help us respond to a request for help, initiated by You, with using the PROGRAM.</p>
        <p>● To provide PROGRAM-related announcements and updates: When You register to become a registered user of the PROGRAM, we use the email address provided during account creation (or any other email address You may specify within Your Registered User account) to deliver important PROGRAM-related announcements to You. Examples of PROGRAM-related announcements include email verification during registration, requests for password reset, important security or server maintenance updates, upgrades, new releases or policy changes, and future offerings of PROGRAM Materials.</p>
        <p>● To provide and publish anonymous, non-personal, aggregate statistics: We may use the information we collect to calculate and publish anonymous, non-personal, aggregate statistics for either the general user base or to the contributors of the PROGRAM Material in order to provide insights about usage of the PROGRAM Materials that they contributed. We publish this data in aggregate form only, and do not publish any personally identifiable information (PII) in connection with this use.</p>
        <p>● For internal reporting and analysis: We use information gathered from log files, cookies, and the like to improve the PROGRAM, analyze trends, administer the site, track the movements of users around the site, and gather aggregate information about PROGRAM’s users as a whole.</p>
        <p>● For research purposes: We may use information as part of a research project, in order to expand the body of knowledge about &lt;Enter Research Area&gt; . In these cases, our research methodology will be reviewed by the University of MICHIGAN Institutional Review Board and we will continue to uphold the commitments made in this document.</p>
        <p>● To satisfy legal, regulatory, and contractual obligations.</p>

        <h2>HOW YOUR INFORMATION IS SHARED</h2>
        <p>MICHIGAN will only disclose Your personal data in the following cases:</p>
        <p>● Disclosure to government officials, as required by law, such as to comply with a subpoena, court order or similar legal process or when we believe in good faith that disclosure is necessary to protect our rights (including in legal proceedings), protect Your safety or the safety of others, investigate fraud, or respond to a government request; and</p>
        <p>● Disclosure to trusted service providers who work on our behalf, who do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</p>

        <h2>HOW YOUR INFORMATION IS SECURED</h2>
        <p>We are concerned about safeguarding the confidentiality of Your information. We provide physical, administrative, and technical safeguards to protect information we process and maintain. For example, we limit access to this information to authorized employees and contractors who need to know that information in order to operate, develop or improve the PROGRAM and PROGRAM Materials. Please be aware that, although we endeavor to provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches.</p>
        <p>We maintain the PROGRAM and its associated data in ways that comply with the Family Education Rights and Privacy Act (FERPA) and <a href="https://ro.umich.edu/records-registration/student-rights-records">MICHIGAN FERPA policies</a>. Data collected by the PROGRAM is stored on XXXX server which is secured by XXXX.</p>

        <h2>DATA RETENTION POLICY, MANAGING YOUR INFORMATION</h2>
        <p>The PROGRAM administers cookies to identify a user across page loads during a session and provide an integrated user experience. These cookies may be blocked through settings in Your browser, but doing so would affect the functioning of the PROGRAM.</p>
        <p>Additionally, we may use Google Analytics Cookies to count visits and traffic sources in order to measure and improve the performance of the PROGRAM. See details about Google Analytics’s <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">usage of cookies on websites</a> and <a href="https://support.google.com/analytics/answer/6004245">privacy policy</a>. You may find out more about <a href="https://policies.google.com/technologies/managing">managing cookies through Your browser</a>. Opting out of or blocking cookies may impact performance.</p>
        <p>We will retain User-Provided Information for as long as You use the PROGRAM and for a reasonable time thereafter.</p>
        <p>If You would like us to access, update, correct, or delete User-Provided Information that You have provided via the PROGRAM, please contact us at cryoedu-support@umich.edu and reference the PROGRAM in Your email. We will respond in a reasonable time. Please note that some or all of the information we collect may be required in order for the PROGRAM to function properly, and we may be required to retain certain information by law.</p>
        <p>If You do not want us to contact You with marketing communications or other information regarding updates to PROGRAM or PROGRAM Materials (or if You agreed to be contacted for such purposes at the time the Application Data was collected by us but You no longer wish to be contacted or have Your information used in such a way), You can let us know by following the unsubscribe instructions on any communications sent to You. You can also unsubscribe at any time by contacting us at cryoedu-support@umich.edu. Please note that if You have registered an account on the PROGRAM, opting out of receiving marketing messages will not apply to Application Data provided to us as a result of signing up to use the PROGRAM, and we reserve the right to send You certain communications relating to Your account, Your registration, and Your use of the PROGRAM, such as messages relating to account management, customer service and system maintenance. These transactional account messages will thus be unaffected if You choose to opt-out of marketing communications.</p>
        <h3>Your Personal Choices</h3>
        <p>Accessing and Editing Your Personal Information. As noted above, Registered Users may request to edit or delete certain information they have submitted to the PROGRAM. Emailing  cryoedu-support@umich.edu will ensure that such information is edited or removed appropriately. </p>
        <p>Editing / Deleting Your Account. You may contact the PROGRAM at any time, by emailing  cryoedu-support@umich.edu to request that Your PII be removed from the PROGRAM, or to request that Your Registered User account be permanently deleted.</p>

        <h2>CHILDREN</h2>
        <p>We do not use the PROGRAM or PROGRAM Materials to knowingly cater or market to children under the age of 13. If a parent or guardian believes that the data about his or her child is incorrect, he or she should contact cryoedu-support@umich.edu. A detailed description of MICHIGAN’s approach to COPPA can be found at <a href="https://umich.edu/about/privacy-statement/#coppa">Notice Specific to Persons Under the Age of 13 or Their Parents or Guardians</a>.</p>

        <h2>CHANGES TO THIS PRIVACY POLICY</h2>
        <p>This Privacy Policy may be updated from time to time for any reason. You are advised to consult this Privacy Policy regularly for any changes. The last revision date is posted at the top of this Privacy Policy.</p>

        <h2>YOUR CONSENT</h2>
        <p>By using the PROGRAM and PROGRAM Materials, You are consenting to MICHIGAN and its partners to process information collected as set forth in this Privacy Policy now and as amended. “Processing,” means using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information, all of which activities will take place in the United States. If You reside outside the U.S., Your information will be transferred to the U.S. where it will be processed and stored under U.S. privacy standards. By using the PROGRAM and providing information to us, You consent to such transfer to, and processing in, the US.</p>

        <h2>NOTE FOR THOSE LOCATED IN THE EUROPEAN UNION (EU)</h2>
        <p>If You reside or are located in the EU, You may have additional rights over the personal data that we collect, as defined under the General Data Protection Regulation (GDPR). A detailed description of these rights and MICHIGAN’s approach to GDPR compliance can be found in <a href="https://umich.edu/about/privacy-statement/#eu">MICHIGAN’s Notice Specific to Persons in the EU</a>. Additionally, all requests concerning access to PII or use restrictions, as well as further inquiries about data use and retention, can be submitted to cryoedu-support@umich.edu by referencing the PROGRAM in your email.</p>

        <h2>DISCLAIMER AND LIMITATION OF LIABILITY</h2>
        <p>A.  THE PROGRAM AND PROGRAM MATERIALS ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. MICHIGAN DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE PROGRAM WILL MEET YOUR REQUIREMENTS OR THAT OPERATION WILL BE UNINTERRUPTED OR ERROR FREE. MICHIGAN shall not be liable for special, indirect, incidental, or consequential damages with respect to any claim on account of or arising from this Agreement or use of the PROGRAM or PROGRAM Materials, even if MICHIGAN has been or is hereafter advised of the possibility of such damages. Because some states do not allow certain exclusions or limitations on implied warranties or of liability for consequential or incidental damages, the above exclusions may not apply to You. In no event, however, will MICHIGAN be liable to You, under any theory of recovery.</p>
        <p>B.  You agree that MICHIGAN has no obligation to provide to You any maintenance, support, or update services. Should MICHIGAN provide any revised versions of the PROGRAM or PROGRAM Materials to You, You agree that this Agreement shall apply to all such revised versions.</p>
        <p>C.  THE PROGRAM MATERIALS HAVE NOT BEEN EVALUATED BY THE FEDERAL DRUG ADMINISTRATION NOR APPROVED BY ANY OTHER GOVERNMENT OR OFFICIAL BODY. NOTHING IN THE PROGRAM MATERIALS IS INTENDED TO BE A SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. IF YOU HAVE CONCERNS ABOUT YOUR HEALTH, YOU SHOULD TALK TO YOUR DOCTOR.  IF YOU THINK YOU ARE HAVING A MEDICAL EMERGENCY, CALL YOUR HEALTH CARE PROFESSIONAL, OR 911, IMMEDIATELY.</p>
        <p>D.  IF YOU ARE DISSATISFIED WITH PROGRAM AND/OR THE PROGRAM MATERIALS FOR ANY REASON, OR WITH ANY OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING PROGRAM AND THE PROGRAM MATERIALS.</p>

        <h2>CONTACT US</h2>
        <p>If You have any questions regarding privacy while using the PROGRAM, or have questions about our practices, please contact us via email at <a href="mailto:cryoedu-support@umich.edu">cryoedu-support@umich.edu</a>.</p>

        </Box>

    )
}