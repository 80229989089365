import {
    Box,
    Button,
    Stack,
    Typography
} from "@mui/material";
import Card from '@mui/material/Card';
import axios from 'axios';
import React, { useEffect, useState } from "react";
import Countdown from 'react-countdown';
import Iframe from 'react-iframe';


const renderer = ({ days, hours, minutes, seconds }) => {
    return (
        <span>
            Time Left: <br></br>
            {days} Days <br></br>
            {hours} Hours <br></br>
            {minutes} Minutes <br></br>
            {seconds} Seconds <br></br>
        </span>
    );
};


/**
 * guacamole 系统的接口
 * @returns 
 */
export default function Cryoedu() {

    const [machineName, setMachineName] = useState("");
    const [expirationTime, setExpirationTime] = useState("");
    const [guacamoleUsername, setGuacamoleUsername] = useState("");

    useEffect(() => {
        const email = localStorage.getItem("email")
        // console.log("email: ", email)
        axios.post('/api/getUserData', { email }).then((res) => {
            // console.log("seated page: ", res.data);
            let data = res.data[0];
            setMachineName(data.assigned_guaca)
            setExpirationTime(data.valid_until)
            setGuacamoleUsername(data.email)
        })
    });
    function calculateTimeDifference(startTime, expirationTime) {
        // Convert the input strings to Date objects
        var startDate = new Date(startTime);
        var expirationDate = new Date(expirationTime);

        // Calculate the time difference in milliseconds
        var timeDiff = Math.abs(expirationDate - startDate);

        // Convert the time difference to days, hours, and minutes
        var days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        var hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        // Return the result as an array of three numbers
        return [days, hours, minutes];
    }

    var currentTime = new Date();
    // var pstcurrentTime = currentTime.toLocaleString("en-US", {
    //     timeZone: "America/Los_Angeles"
    // })

    let sessionTime = calculateTimeDifference(currentTime, expirationTime)
    let username = "username=" + guacamoleUsername;
    let password = "password=" + guacamoleUsername;
    // let url = "http://54.183.218.2:8080/cryoedu/?" + username + "&" + password;
    // let url = "https://portal.cryoedu.org:8080/cryoedu/?" + username + "&" + password;
    let url = "https://portal.cryoedu.org/tomcat/?" + username + "&" + password;
    // let url = "http://132.249.229.224:8080/cryoedu/?username=user020&password=particle_user020"
    return (
        <Stack direction="row" spacing={1} sx={{ height: 675, m: 1 }}>
            {/* <Iframe url={url} /> */}
	    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", mb: 1 }}>
                <Iframe url={url}
                    width="100%"
                    height="100%"
                    display="block"
                    position="relative"
	            allow="fullscreen"
                />
            </Box>
            <Stack direction="column" sx={{ width: "10%", mb: 1 }} justifyContent="space-between">
                <Card variant="outlined">
                    <Typography>
                        Entered Machine:
                    </Typography>
                    <Typography>
                        {machineName}
                    </Typography>
                </Card>
                <Card>
                    <Typography>
                        <Countdown date={expirationTime} renderer={renderer} />
                    </Typography>
                </Card>
                <Button variant="contained" href='/home' sx={{ mb: 1 }}>EXIT</Button>
            </Stack>
        </Stack>
    )
}
