import React, { useState } from 'react';

import {
    Avatar,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Box,
    Tab,
    Tabs,
    Stack,
    Typography,
    Container,
    Paper,
  } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios'

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
export default function MailBox() {
    const data = localStorage.getItem('myData');
    console.log("locals_data: ",data); // Output: Hello, World!
    const [sendFrom, setSendFrom] = React.useState("");
    const [sendTo, setSendTo] = React.useState("");
    const [sendSubject, setSendSubject] = React.useState("");
    const [sendContent, setSendContent] = React.useState("");
    const [sendSuccess, setSendSuccess] = React.useState(false);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const buttonSubmit = (event) => {
        event.preventDefault();
        console.log(`from: ${sendFrom}, to: ${sendTo}, subject: ${sendSubject}, content: ${sendContent}`);

        axios.post('/api/mailbox', {sendSubject, sendContent}).then((res) => {
        console.log("res: ",res);
        // show success message and clear inputs
        if(res.data === "SENT"){
            setSendSuccess(true);
            setSendFrom("")
            setSendContent("")
            setSendSubject("")
            setSendTo("")
        }
    })
    }
    const successAlert = () => {
        if (sendSuccess) {
          return <Alert severity="success">
                  <AlertTitle>Success</AlertTitle>
                   — <strong>Email Sent!</strong>
              </Alert>
        }
        else{
          return <></>
        }
      }

    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            {/* <Stack direction="row" spacing={2} sx = {{width:"100%", mt:1}}>
                <Typography>From: </Typography>
                <TextField hiddenLabel 
                        variant="outlined" 
                        size="small" 
                        sx={{width:"25%"}} 
                        value= {sendFrom} 
                        onChange={(e) => {setSendFrom(e.target.value)}}
                        placeholder="Type your name"/>
                <Typography>To: </Typography>
                <TextField hiddenLabel 
                        value= {sendTo} 
                        size="small" 
                        variant="outlined" 
                        sx={{width:"25%"}}
                        value = {sendTo}
                        onChange={(e) => setSendTo(e.target.value)}
                        placeholder= "han3@vt.edu"/>
            </Stack> */}
            <Stack direction="row" spacing={2} sx = {{width:"100%", mt:1}}>
                <Typography>Subject: </Typography>
                <TextField hiddenLabel 
                        variant="outlined" 
                        size="small" 
                        sx={{width:"50%"}} 
                        value = {sendSubject}
                        onChange={(e) => setSendSubject(e.target.value)}/>
            </Stack>
            <TextField
                placeholder="Please describe your issue or concerns here."
                multiline
                rows={10}
                value = {sendContent}
                onChange={(e) => setSendContent(e.target.value)}
                />
            
            <Button variant="contained" sx = {{width:"10%"}} endIcon={<SendIcon />} onClick={buttonSubmit}>Send</Button>
            {successAlert()}
        </Stack>
    )
}