import Box from '@mui/material/Box';


export default function Terms() {
    return (
        <Box>

        <h1>Terms of Service</h1>
        <p>Date last updated: November 16, 2023</p>

        <p>The Regents of the University of Michigan (“MICHIGAN”) are making the Materials (as defined below) available to you (“You” or “Your”) through the cryoEDU website located at cryoEDU.org (“PROGRAM”) for non-commercial research and education purposes only. Please read these terms of service carefully before using PROGRAM or the PROGRAM Materials. These terms of service govern Your access to and use of PROGRAM and the PROGRAM Materials and create a binding agreement ( “Agreement”) between You and MICHIGAN.</p>

        <p>IF YOU DON’T UNDERSTAND HOW THIS AGREEMENT APPLIES TO YOUR ACCESS TO OR USE OF THE PROGRAM MATERIALS, OR YOU THINK THAT YOUR ACCESS TO OR USE OF THE PROGRAM MATERIALS WOULD BE IN VIOLATION OF THIS AGREEMENT, YOU SHOULD CONTACT PROGRAM (see section 10) BEFORE YOU ACCESS OR USE THE PROGRAM MATERIALS. YOU MUST BE AUTHORIZED TO ACCEPT THESE TERMS AND CONDITIONS AS AN INDIVIDUAL. IF YOU DON’T OR CAN’T ACCEPT THESE TERMS AND CONDITIONS PLEASE STOP HERE AND DO NOT ACCESS OR USE PROGRAM OR THE PROGRAM MATERIALS.  PROGRAM IS NOT INTENTED FOR USE BY ANYONE UNDER THE AGE OF 13.  IF YOU ARE UNDER 13, YOU ARE NOT PERMITTED TO USE PROGRAM.</p>

        <h2>1. PROGRAM Materials</h2>

        <p>As used in this Agreement, “PROGRAM Materials” means the data, videos, images, text, calculators, and related materials provided through or in connection with PROGRAM. Except for the access and use rights granted in this Agreement, or as otherwise indicated to You separately in writing by MICHIGAN, You have no right, title, or interest in or to PROGRAM or the PROGRAM Materials.</p>

        <h2>2. Access</h2>
        <p>Regardless of the physical location from which You access or use PROGRAM and the PROGRAM Materials, You will be considered to be accessing or using information technology of the University of MICHIGAN. </p>

        <h2>3. Copyright and Other Restrictions</h2>
        <p>Unless otherwise specified, the creators of PROGRAM Materials own the copyright to their content. MICHIGAN respects copyright law and requires You to do the same. MICHIGAN expressly prohibits the use of PROGRAM for the illegal transmission of copyrighted material, and will, if notified by content owners, disable copyrighted material from being transmitted via PROGRAM.</p>
        <p>You agree that You will not modify or alter the PROGRAM Materials except as authorized in writing. The PROGRAM Materials may contain a proprietary software built for educational purposes. You agree not to use with any other materials. You also agree not to bypass, delete, or disable any copy protection mechanisms or any security mechanisms in PROGRAM or the PROGRAM Materials.</p>
        <p>Any use of PROGRAM or the PROGRAM Materials other than as specifically authorized herein, without the express prior written permission of MICHIGAN, is prohibited.</p>

        <h2>4. Code of Conduct</h2>
        <p>You are responsible for all data and content that You access from PROGRAM or create using the PROGRAM Materials (collectively, “Data”). In addition to any other terms and conditions of this Agreement, YOU AGREE TO ABIDE BY THE FOLLOWING RULES:</p>
        <p>● You will not share passwords or other access information or devices or otherwise authorize any third party to access or use PROGRAM or the PROGRAM Materials on Your behalf.</p>
        <p>● You will not engage in unlawful, illegal, or fraudulent activity, including, without limitation, by engaging in unauthorized access or unauthorized use of PROGRAM or the PROGRAM Materials or any accounts, computers, or networks related to PROGRAM.</p>
        <p>● You will not use PROGRAM to make available any unsolicited or unauthorized advertising or promotional materials.</p>
        <p>● You will not reverse engineer any portion of PROGRAM or the PROGRAM Materials.</p>
        <p>● Unless indicated to You separately in writing by MICHIGAN, You will not use any robot, spider, site search/retrieval application, or other manual or automated process to access, retrieve, scrape, or index any portion of PROGRAM or the PROGRAM Materials.</p>
        <p>● You will not record, process, or mine information about other users or creators of the PROGRAM or the PROGRAM Materials.</p>
        <p>● You will not damage, disrupt, interfere with, diminish, or render inaccessible or unusable PROGRAM or the PROGRAM Materials, PROGRAM or others’ equipment or software or others’ data, or attempt to do so, or encourage or assist others to do so.</p>
        <p>● You will not initiate a denial of service attack against PROGRAM or the PROGRAM Materials or release a virus, trojan horse, worms or other malware or spyware.</p>
        <p>● You will not use the PROGRAM Materials to perpetrate a hoax or engage in phishing schemes or forgery or other similar falsification or manipulation of data.</p>
        <p>● You will not use the PROGRAM Materials to abuse, harass, stalk, threaten, or otherwise violate the legal rights of others.</p>
        <p>● You will not use the PROGRAM Materials to libel or defame others.</p>
        <p>● You will not use PROGRAM to make available any content that is vulgar or obscene.</p>
        <p>● You will not resell or charge others for the PROGRAM Materials, either directly or indirectly.</p>
        <p>● You will abide by reasonable administrative directives issued from time to time concerning the access or use of PROGRAM or the PROGRAM Materials.</p>

        <h2>5. Suspension and Termination of Your Use of PROGRAM</h2>
        <p>If You breach or violate the terms and conditions of this Agreement, Your access to PROGRAM and use of the PROGRAM Materials may be suspended or terminated without prior notice to You. You acknowledge that if Your access to and/or use of PROGRAM and the PROGRAM Materials is suspended or terminated, it is possible that You may no longer have access to the content that You created.</p>
        <p>You agree that if You breach this Agreement, MICHIGAN may take any available legal actions.</p>

        <h2>6. Changes to Agreement</h2>
        <p>MICHIGAN reserves the right to update and revise this Agreement at any time. MICHIGAN will change the “Effective Date” at the top of this page so You can determine if the terms of this Agreement have changed since Your last visit.  You should routinely check the web page to find out if any changes have been made that affect Your use of the PROGRAM or PROGRAM Materials. Unless otherwise specified, changes made to this Agreement will be effective when they are posted to the web page. IF YOU DO NOT AGREE WITH ANY CHANGES THAT HAVE BEEN MADE, YOU MUST TERMINATE YOUR USE OF PROGRAM AND THE PROGRAM MATERIALS.</p>

        <h2>7. Monitoring</h2>
        <p>MICHIGAN may monitor Your use of PROGRAM and the PROGRAM Materials to assess compliance with the terms of this Agreement.</p>

        <h2>8. Disclaimer of Warranty and Limitation of Liability</h2>
        <p>A.  THE PROGRAM AND PROGRAM MATERIALS ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. MICHIGAN DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE PROGRAM WILL MEET YOUR REQUIREMENTS OR THAT OPERATION WILL BE UNINTERRUPTED OR ERROR FREE. MICHIGAN shall not be liable for special, indirect, incidental, or consequential damages with respect to any claim on account of or arising from this Agreement or use of the PROGRAM or PROGRAM Materials, even if MICHIGAN has been or is hereafter advised of the possibility of such damages. Because some states do not allow certain exclusions or limitations on implied warranties or of liability for consequential or incidental damages, the above exclusions may not apply to You. In no event, however, will MICHIGAN be liable to You, under any theory of recovery.</p>
        <p>B.  You agree that MICHIGAN has no obligation to provide to You any maintenance, support, or update services. Should MICHIGAN provide any revised versions of the PROGRAM or PROGRAM Materials to You, You agree that this Agreement shall apply to all such revised versions.</p>
        <p>C.  THE PROGRAM MATERIALS HAVE NOT BEEN EVALUATED BY THE FEDERAL DRUG ADMINISTRATION NOR APPROVED BY ANY OTHER GOVERNMENT OR OFFICIAL BODY. NOTHING IN THE PROGRAM MATERIALS IS INTENDED TO BE A SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. IF YOU HAVE CONCERNS ABOUT YOUR HEALTH, YOU SHOULD TALK TO YOUR DOCTOR.  IF YOU THINK YOU ARE HAVING A MEDICAL EMERGENCY, CALL YOUR HEALTH CARE PROFESSIONAL, OR 911, IMMEDIATELY.</p>
        <p>D.  IF YOU ARE DISSATISFIED WITH PROGRAM AND/OR THE PROGRAM MATERIALS FOR ANY REASON, OR WITH ANY OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING PROGRAM AND THE PROGRAM MATERIALS.</p>

        <h2>9. Governing Law</h2>
        <p>This Agreement will be governed by and construed in accordance with the laws of the State of MICHIGAN.</p>

        <h2>10. General</h2>
        <p>Your access to and use of PROGRAM and PROGRAM Materials are governed by this Agreement and any other terms agreed to in writing. In the event You have any questions about this Agreement, You should contact cryoedu-support@umich.edu.</p>
        <p>If any part of this Agreement is determined to be invalid or unenforceable then the invalid or unenforceable provision will be deemed superseded by a valid enforceable provision that most closely matches the intent of the original provision, and the remainder of this Agreement will continue in effect. The failure of MICHIGAN to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision. You are prohibited from assigning this Agreement or transferring Your rights under this Agreement to anyone else.</p>

        <h2>11. Third-party content</h2>
        <p>Through the PROGRAM, You will have the ability to access and/or use content created by other users and/or other third parties, including links to websites and services maintained by third parties. MICHIGAN cannot guarantee that such third-party content, in the PROGRAM or elsewhere, will be free of material You may find objectionable or otherwise inappropriate or free of malware or other contaminants that may harm Your computer, mobile device, or any files therein. Privacy policies and practices for such linked websites may differ from our Privacy Policy (insert link to Privacy Policy). MICHIGAN disclaims any responsibility or liability related to Your access or use of, or inability to access or use, such third-party content.</p>

        <h2>12. Content You Provide to the PROGRAM</h2>
        <p>The PROGRAM enables you to share Your content such as screenshots of your RELION simulatorand the like (“Content”), with the PROGRAM and its users. </p>
        <p>To the extent that you provide Content, you grant the PROGRAM and MICHIGAN a fully-transferable, royalty-free, perpetual, sublicensable, non-exclusive, worldwide license to copy, distribute, modify, create derivative works based on, publicly perform, publicly display, and otherwise use the Content. This license includes granting the PROGRAM and MICHIGAN the right to use Content with their registered students, on-campus learners, or other learners independent of the PROGRAM. Nothing in this Agreement shall restrict other legal rights the PROGRAM may have to Content, for example under other licenses. We reserve the right to remove or modify Content for any reason, including Content that we believe violates this Agreement or other policies including our Privacy Policy [insert link to Privacy Policy].</p>

        </Box>

    )
}