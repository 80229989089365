import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
// import { FAQdata } from './FAQData';


export default function FAQ(){
  const FAQdata = [
    {
      id: 1,
      question: 'What is your return policy?',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
      id: 2,
      question: 'How do I track my order?',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
      id: 3,
      question: 'Do you offer gift cards?',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
      id: 4,
      question: 'Can I cancel my order?',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
      id: 5,
      question: 'What is your return policy?',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
      id: 6,
      question: 'How do I track my order?',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
      id: 7,
      question: 'Do you offer gift cards?',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
      id: 8,
      question: 'Can I cancel my order?',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    }
  ];
  const [searchTerm, setSearchTerm] = useState('');
  const filteredFAQ = FAQdata.filter((faq) =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box sx={{display: "flex", flexDirection: "column", height: "100%",overflow: "hidden", overflowY: "scroll"}}>
      <Typography variant="h4" component="h1" sx={{ mb: 3 }}>
        Frequently Asked Questions
      </Typography>
      <TextField
        id="search"
        label="Search by keywords"
        value={searchTerm}
        onChange={handleChange}
        variant="outlined"
        sx={{ mb: 3 }}
      />
      {filteredFAQ.map((faq, index) => (
        <Box key={index} sx={{ mb: 2}}>
          <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
            {faq.question}
          </Typography>
          <Typography variant="body1">{faq.answer}</Typography>
        </Box>
      ))}
      
    </Box>
  );
};

