import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import axios from 'axios'

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

/**
 * 注册界面
 * @returns 
 */
const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [registerMessage, setRegisterMessage] = useState("");
  const [organization, setOrganization] = useState("")
  const navigate = useNavigate();

  const isDisabled = !(firstName && lastName && email && isAgreed && organization);

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  function handleSendEmail(event) {
    
    event.preventDefault();
    axios.post('/api/register', {firstName, lastName, email, organization}).then((res) => {
        console.log("register page: ", res.data[0]);
        setRegisterMessage(res.data[1])

        if (!res.data[0]){
          setEmailValid(false);
        }
        else{
          navigate("/");
        }
      })
  }

  const emailWarning = () => {
    if (!emailValid) {
      return <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Sorry — <strong>{registerMessage}</strong>
          </Alert>
    }
    else{
      return <></>
    }
  }

  return (
    <Box sx={{height:680, mt:1}}>
    <Container component="main" maxWidth="xs">
      <div>
        <Typography component="h1" variant="h5" mb={2}>
          Create a New Account
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="organization"
                label="Organization"
                name="organization"
                autoComplete="organization"
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
              />
            </Grid>
          
            <Grid item xs={12} mb={1}>
            
            <label>
              <input
                type="checkbox"
                checked={isAgreed}
                onChange={(event) => setIsAgreed(event.target.checked)}
              />
              I agree to the terms and conditions.
            </label>
       

            </Grid>
          </Grid>
          {/* <Link href= "/home"> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSendEmail}
              disabled={isDisabled}
            >
            Sign Up 
            </Button>
          {/* </Link> */}
          <Grid container justify="flex-end" mt={1}>
            <Grid item>
              <Link href="/" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
        
      </div>
      {emailWarning()}
    </Container>
    </Box>
  );
};

export default Register;
