import React, { useState, useEffect } from 'react';
import axios from 'axios'
import {
    Avatar,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Box,
    Tab,
    Tabs,
    Typography,
    Container,
    Paper,
} from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';


/**
 * 用户未被分配到机器，进入等待
 * 此功能未完成，需要进一步开发
 * @returns 
 */
export default function HomeWait() {
    const [numWaiting, setNumWaiting] = useState(5)
    const [moreThanOne, setMoreThanOne] = useState("are")
    const [sendSuccess, setSendSuccess] = React.useState(false);
    const [sendSuccessMsg, setSendSuccessMsg] = React.useState("");

    // useEffect(() => {
    //     // const email = localStorage.getItem("email")
    //     axios.post('/api/getNumWaiting', {numWaiting}).then((res) => {
    //         // console.log("seated page: ", res.data);
    //         let data = res.data;
    //         // console.log(data)
    //         setNumWaiting(data)
    //         if(numWaiting > 1) {
    //             setMoreThanOne("are")
    //         }
    //     })  
    // });

    const buttonSubmit = (event) => {
        event.preventDefault();

        axios.post('/api/requeue').then((res) => {
            if (res.data === "SENT") {
                setSendSuccess(true);
                setSendSuccessMsg("Sent! We will then notify you by email if new connection is available.")
            }
            if (res.data === "Q") {
                setSendSuccess(true);
                setSendSuccessMsg("Already in queue! We will then notify you by email if new connection is available.")
            }
        })
    }

    const successAlert = () => {
        if (sendSuccess) {
            return <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                — <strong>{sendSuccessMsg}</strong>
            </Alert>
        }
        else {
            return <></>
        }
    }

                //<strong>{numWaiting}</strong> people {moreThanOne} ahead of you now.
    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="info">
                <AlertTitle>Waiting Position</AlertTitle>
                Please refresh this page every few minutes to check if you have seat.
                Thank you for your patience. cryoEDU seats last for 60 minutes at a time.
                If you have any questions, please reach out to us through the <b>CONTACT US</b> page.
            </Alert>
            <Button variant="contained" sx={{ width: "15%" }} endIcon={<SendIcon />} onClick={buttonSubmit}>Requeue</Button>
            {successAlert()}
        </Stack>
    )
}
