import React, { useEffect, useState } from "react";

import {
    Box,
    Button,
    Typography,
} from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import axios from 'axios';
// import { useImmer } from 'use-immer';


/**
 * 用户被分配到了机器
 * @returns 
 */
export default function HomeSeated() {
    const [machineName, setMachineName] = useState("");
    const [startTime, setStartTime] = useState("");
    const [expirationTime, setExpirationTime] = useState("");
    // const [guacamoleUsername, setGuacamoleUsername] = localStorage.getItem("email");
    const guacamoleUsername = localStorage.getItem("email");
    const [guacamoleToken, setGuacamoleToken] = useState("");

    useEffect(() => {
        const email = localStorage.getItem("email")
        // console.log("email: ", email)
        axios.post('/api/getUserData', { email }).then((res) => {
            // console.log("seated page: ", res.data);
            let data = res.data[0];
            let startDate = new Date(data.valid_from);
            let endDate = new Date(data.valid_until);
            console.log("seated data: ", data)
            console.log("seated data: ", startDate)
            console.log("seated data: ", endDate)
            setMachineName(data.assigned_guaca)
            // setStartTime(startDate.toISOString().split('.')[0].replace('T', ' '))
            // setExpirationTime(endDate.toISOString().split('.')[0].replace('T', ' '))
            setStartTime(startDate.toLocaleString())
            setExpirationTime(endDate.toLocaleString())
            // setGuacamoleUsername(data.email)
            setGuacamoleToken(data.token)
        })

        let username = guacamoleUsername;
        let password = guacamoleUsername;
        // let url = "/tomcat/?" + username + "&" + password;
        // let url = '/tomcat/api/tokens'
        // const formData = new FormData();
        // formData.append("username", guacamoleUsername)
        // formData.append("password", guacamoleUsername)

        // axios.post('/tomcat/api/tokens', null, {params: {username, password}}).then((res) => {
        //     console.log(res.data)
        //     let data = res.data
        //     if (typeof data.authToken !== "undefined") {
        //         console.log('token has value!')
        //         // setGuacamoleToken(data.authToken)
        //         // setGuacamoleUsername(data.authToken)
        //     }
        // }).catch((error) => {})
    }, []);

    let url="/tomcat?token=" + guacamoleToken;

    const openInNewTab = (url) => {
        // window.location.reload(false);
        window.open(url, "_blank", "noreferrer");
    };
    
    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                Congratulations — <strong>You were seated!</strong>
            </Alert>

            <Stack direction="row" spacing={2} sx={{ width: "100%", mt: 1 }}>
                <List sx={{ width: '30%' }}>
                    <ListItem>
                        <ListItemText primary="Assigned Machine Number: " />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Session Starting Time: " />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Session Expiration Time: " />
                    </ListItem>
                </List>
                <List sx={{ width: '30%' }}>
                    <ListItem>
                        <ListItemText primary={machineName} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={startTime} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={expirationTime} />
                    </ListItem>
                    {/* <ListItem>
                        <ListItemText primary={guacamoleToken} />
                    </ListItem> */}
                </List>
            </Stack>
            <Box sx={{ mt: 5 }}>
                <Stack direction="row" spacing={2} sx={{ width: "100%", mt: 1 }}>
                    <Typography sx={{ mt: 1, ml: 2, mr: 6 }}>
                        Please enter the assigned virtual machine:
                    </Typography>
                    <Button
                        variant="contained"
                        // href='/cryoedu'
                        // onClick={() => openInNewTab("/cryoedu")}
                        onClick={() => openInNewTab(`${url}`)}
                    >
                        Enter
                    </Button>
                    {/* <Button variant="contained" href={`${url}`}>Enter</Button> */}
                    {/* <Button variant="contained" href='/tomcat'>Enter</Button> */}
                    {/* <Button variant="contained" href={`/tomcat?token=${guacamoleToken}`}>Enter</Button> */}
                </Stack>
            </Box>
        </Stack>
    )
}
