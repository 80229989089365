import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Paper,
} from "@mui/material";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import axios from 'axios'

/**
 * 登陆界面
 * @returns 
 */
export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const isDisabled = !(email && password);
  const [loginValid, setLoginValid] = useState(true);
  const [loginMessage, setLoginMessage] = useState("");


  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Email: ${email}, Password: ${password}, Remember Me: ${remember}`);

    axios.post('/api/login', {email, password}).then((res) => {
      console.log("login page: ", res.data[0]);
      setLoginMessage(res.data[1])
      if (!res.data[0]){
        setLoginValid(false);
      }
      else{
        localStorage.setItem("email", email);
        navigate("/home");
      }
      
    })
  };

  const loginWarning = () => {
    if (!loginValid) {
      return <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Sorry — <strong>{loginMessage}</strong>
          </Alert>
    }
    else{
      return <></>
    }
  }

  return (
    <Box sx={{height: 680, mt:1}}>
    <Container maxWidth="xs"  
              display= 'flex'>
      <div>
        <Typography component="h1" variant="h5" mb={2}>
          Sign in
        </Typography>
        <>Welcome back to CryoEDU!</>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
            checked={remember}
            onChange={(e) => setRemember(e.target.checked)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            // href='/home'
            disabled={isDisabled}
          >
            Log In
          </Button>
          <Grid container>
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid> */}
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      {loginWarning()}
    </Container>
    </Box>
  );
}