import React, {useEffect} from 'react';
import axios from 'axios'
import {
    Link,
    Box,
    Tab,
    Tabs,
    Typography,
    Stack,
  } from "@mui/material";
import FAQ from './FAQ'
import HomeWait from './HomeWait';
import HomeSeated from './HomeSeated';
import MailBox from './MailBox';

let numWaiting = "5"; // testing-use number

/**
 * 主页面
 * @returns 
 */
export default function Home() {
    const [tabValue, setTabValue] = React.useState("home");
    const email = localStorage.getItem("email")
    const [homeStatus, setHomeStatus] = React.useState("WAIT");


    useEffect(() => {
        const email = localStorage.getItem("email")
        // console.log("email: ", email)
        axios.post('/api/getUserData', {email}).then((res) => {
            let data = res.data;
            if(data) {
                setHomeStatus("SEATED")
            }
        })  
    });

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    console.log(homeStatus)
    const display = () => {
        if (tabValue === "home"){
            if (homeStatus === "WAIT"){
                return (<HomeWait numWaiting={numWaiting}/>)
            }
            else if (homeStatus === "SEATED"){
                return (<HomeSeated/>)
            }
        }
        else if (tabValue === "mailbox"){
            return (<MailBox/>)
        }
        else if (tabValue === "fq"){
            return (<FAQ/>)
        }
    } 
    return (
        <Box>
            <Stack direction="row" spacing={1} sx={{display: "flex",justifyContent: "flex-end", mr:2}}>
                <Typography>Logged in as </Typography>
                <Typography sx={{color: "steelblue"}}>{email}</Typography>
            </Stack>
            <Link href="/" underline="none" sx={{color: "steelblue", display: "flex",justifyContent: "flex-end", mr:2}}>
                <Typography>log out</Typography>
            </Link>
            <Stack direction="row" spacing={2} sx = {{height: 680}}>

                <Box sx = {{width: 200, mt:5}}>
                    <Tabs value={tabValue} onChange={handleChange} centered orientation="vertical">
                        <Tab label="Home" value="home"/>
                        <Tab label="Contact us" value="mailbox"/>
                        <Tab label="FAQ" value="fq"/>
                    </Tabs>
                </Box>
                <Box sx = {{width: "100%", padding: "50px",}}>
                    {display()}
                </Box>
            </Stack>
        </Box>
    )
}