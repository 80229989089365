
import { Paper, Stack } from "@mui/material";
import * as React from 'react';
import CryoLogo from "../../Images/Cryoedu.png";

export default function Header() {

    return (
        <>
            <Paper
                sx={{
                    margin: '.2rem .2rem .2rem .2rem',
                    // width: '100%',
                    // overflow: "auto"
                    }}>

                    <Stack direction="row" 
                            spacing={2} 
                            justifyContent="space-between"
                            alignItems="center">
                        {/* <img src={SPLogo} style = {{height: 60}} alt={""}/>  */}
                        <img src={CryoLogo} style = {{height: 60}} alt={""}/> 
                        {/* <Box>
                            
                        </Box> */}
                    </Stack>
            </Paper>
        </>
    )
}
