import { Paper } from "@mui/material";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import Cryoedu from './Components/Cryoedu/Cryoedu';
import Header from './Components/Home/Header';
import Home from './Components/Home/Home';
import StickyFooter from "./Components/Home/StickyFooter";
import Login from './Components/Login/Login';
import Register from './Components/Register/Register';
// import {email} from './Components/Login/Login'

function App() {
  return (
    <Paper sx={{
      width: '100%',
      height: '100%',
      display: 'inline',
    }}>

      {/* <Header/> */}
      {/* <Paper>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Login/>} />
              <Route exact path="/register" element={<Register/>} />
              <Route exact path="/home" element={<Home/>} />
              <Route exact path="/cryoedu" element={<Cryoedu/>} />
            </Routes>
          </BrowserRouter>
        </Paper> */}
      {/* <Footer/> */}
      <StickyFooter />
    </Paper>
  );
}

export default App;
