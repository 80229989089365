import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Cryoedu from '../Cryoedu/Cryoedu';
import Login from '../Login/Login';
import Register from '../Register/Register';
import Header from './Header';
import Home from './Home';
import Terms from '../Terms/Terms';
import Privacy from '../Privacy/Privacy';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary">
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function StickyFooter() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <CssBaseline />
                {/* <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
          <Typography variant="h2" component="h1" gutterBottom>
            Sticky footer
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            {'Pin a footer to the bottom of the viewport.'}
            {'The footer will move as the main element of the page grows.'}
          </Typography>
          <Typography variant="body1">Sticky footer placeholder.</Typography>
        </Container> */}
                <Header />
                <Container>
                    <BrowserRouter>
                        <Routes>
                            <Route exact path="/" element={<Login />} />
                            <Route exact path="/register" element={<Register />} />
                            <Route exact path="/home" element={<Home />} />
                            <Route exact path="/cryoedu" element={<Cryoedu />} />
                            <Route exact path="/terms" element={<Terms />} />
                            <Route exact path="/privacy" element={<Privacy />} />
                        </Routes>
                    </BrowserRouter>
                </Container>
                <Box
                    component="footer"
                    sx={{
                        py: 3,
                        px: 2,
                        mt: 'auto',
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[200]
                                : theme.palette.grey[800],
                    }}
                >
                    <Container maxWidth="sm">
                        {/* <Typography variant="body1">
                            My sticky footer can be found here.
                        </Typography>
                        <Copyright /> */}
                        <Typography variant="body2" color="textSecondary" align="center">
                            © 2023 CryoEDU. All rights reserved.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" align="center">
                            <Link href="/privacy">Privacy Policy</Link>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" align="center">
                            <Link href="/terms">Terms of Service</Link>
                        </Typography>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
